<template>
<div>
    <SideBar :active="'orders'"/>
    <div class="order-page">
        <div class="loader" v-if="!order">
            <div class="orders-top-pane flex align-center justify-btw">

                <div class="pseudo-loader"></div>

            </div>
            <div class="order-page-wrapper">
                <div class="order-page-top-pane flex align-center">
                    <div class="pseudo-loader"></div>
                    <div class="pseudo-loader ml-auto small round"></div>
                    <div class="pseudo-loader ml-3 small round"></div>
                </div>
            </div>
        </div>

        <div class="orders-top-pane flex align-center" v-if="order">
            <h2> Edit Order - {{ order.orderID }} </h2>
        </div>

        <div class="order-page-wrapper" v-if="order">

            <div class="order-page-top-pane flex align-center">

                <span :class="classObj" class="status">
                    {{ status }}
                </span>

                <div class="admin-icons save-icon" @click="openSaveOverlay"></div>
                <div class="admin-icons cancel-icon" @click="openCancelOverlay"></div>

            </div>

            <h3 class="sub-headings">Order Details</h3>

            <div class="order-page-row flex justify-btw align-center">
                <div class="flex order-page-entry">
                    <span class="order-id"> Order ID: </span>
                    <p class="order-id"> {{ order.orderID }} </p>
                </div>
                <div class="flex order-page-entry">
                    <span> Sim given: </span>
                    <div class="form-element flex">
                        <label class="switch">
                            <input type="checkbox" v-model="order.withSim">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="order-page-row flex justify-btw align-center">
                <div class="flex order-page-entry">
                    <span> Brand: </span>
                    <div class="form-element">
                        <input
                            type="text" data-name="brand" data-len="3"
                            id="brand" class="form-input"
                            placeholder="Mobile brand*" v-model="order.brand"
                            @input="handleInput" @blur="validateFields">
                    </div>
                </div>
                <div class="flex order-page-entry">
                    <span> Model: </span>
                    <div class="form-element c-email-outer">
                        <input
                            type="text" data-name="model" data-len="1"
                            id="model" class="form-input"
                            placeholder="Mobile model*" v-model="order.model"
                            @input="handleInput" @blur="validateFields">
                    </div>
                </div>
            </div>

            <div class="order-page-row flex justify-btw align-center">
                <div class="flex order-page-entry">
                    <span> Issue: </span>
                    <div class="form-element issue-fe">
                        <textarea
                            data-name="issue" data-len="10"
                            id="issue" class="form-input issue"
                            placeholder="Issue in mobile*" v-model="order.issue"
                            @input="handleInput" @blur="validateFields">
                        </textarea>
                    </div>
                </div>

                <div class="flex order-page-entry">
                    <span> Estimated Price: </span>
                    <div class="form-element c-email-outer">
                        <input
                            type="text" data-name="estimated price" data-len="1"
                            id="estimated-price" class="form-input"
                            placeholder="Estimated price*" v-model="order.priceEstimate"
                            @input="handleInput" @blur="validateFields">
                    </div>
                </div>
            </div>

            <div class="order-page-row flex justify-btw align-center mb-5">
                <div class="flex order-page-entry">
                    <span> Expected Delivery: </span>
                    <div class="date-outer form-element">
                        <input id="proxy-date" style="display: none;" data-name="date" type="hidden" />
                        <Datepicker
                            class="date-picker" placeholder="Expected Delivery Date"
                            v-model="order.expectedDeliveryDate" wrapper-class="exp-date-wrapper"
                            input-class="exp-date-input-1" calendar-class="exp-date-cal"
                            :monday-first="true" :disabled-dates="disabledDates"
                        >
                        </Datepicker>
                        <div class="icon-sprite dropdown"></div>
                    </div>
                </div>
                <div class="flex order-page-entry">
                    <span> Expected Delivery Time: </span>
                    <div class="time-outer form-element">
                        <select id="app-time" class="form-input" v-model="order.expectedDeliveryTime" data-name="time">
                            <option v-for="(t,i) in timeStramp" :key="i" :value="t.time"
                                :disabled="t.disabled === true">
                                    {{ t.time }}
                            </option>
                        </select>
                        <div class="icon-sprite dropdown"></div>
                    </div>
                </div>
            </div>

            <h3 class="sub-headings">Customer Details</h3>

            <div class="order-page-row flex justify-btw align-center">
                <div class="flex order-page-entry">
                    <span> Customer Name: </span>
                    <div class="form-element">
                        <input
                            type="text" data-name="customer name" data-len="4"
                            id="customer-name" class="form-input"
                            placeholder="Customer name*" v-model="order.customerName"
                            @input="handleInput" @blur="validateFields">
                    </div>
                </div>
                <div class="flex order-page-entry">
                    <span> Customer Email: </span>
                    <div class="form-element c-email-outer">
                        <input
                            type="email" data-name="customer email"
                            id="c-email" class="form-input c-email"
                            placeholder="Customer Email" v-model="order.customerEmail"
                            @input="handleInput" @blur="validateEmail">
                    </div>
                </div>
            </div>

            <div class="order-page-row flex justify-btw align-center mb-5">
                <div class="flex order-page-entry">
                    <span> Customer Mobile: </span>
                    <div class="form-element">
                        <input
                            type="number" data-name="Mobile Number" data-len="10"
                            id="c-mobile-number" class="form-input"
                            placeholder="Mobile Number" v-model="order.mobileNumber"
                            @input="handleInput" @blur="validateFields">
                    </div>
                </div>
            </div>

            <h3 class="sub-headings">Other Details</h3>

            <div class="order-page-row flex justify-btw align-center">
                <div class="flex order-page-entry">
                    <span> Order Date: </span> <p> {{ makeDate(order.orderDate) }} </p>
                </div>
                <div class="flex order-page-entry">
                    <span> Order Time: </span> <p> {{ makeTime(order.orderDate) }} </p>
                </div>
            </div>

            <div class="order-page-row flex justify-btw align-center">
                <div class="flex order-page-entry">
                    <span> Fixed: </span> <p> {{ order.fixed ? "Yes" : "No" }} </p>
                </div>
                <div class="flex order-page-entry">
                    <span> Delivered: </span> <p> {{ order.delivered ? "Yes" : "No" }} </p>
                </div>
            </div>

             <div
                class="order-page-row flex justify-btw align-center"
                :class="{'mb-5': status === 'delivered'}">
                <div class="flex order-page-entry">
                    <span> Order Taken By: </span> <p> {{ order.takenBy }} </p>
                </div>
                <div class="flex order-page-entry" v-if="order.fixedBy">
                    <span> Order Fixed By: </span> <p> {{ order.fixedBy }} </p>
                </div>
            </div>

            <div v-if="status === 'delivered'">
                <h3 class="sub-headings">Delivery Details</h3>
                <div class="order-page-row flex justify-btw align-center">
                    <div class="flex order-page-entry">
                        <span> Order Delivered By: </span> <p> {{ order.deliveredBy }} </p>
                    </div>
                    <div class="flex order-page-entry" v-if="order.fixedBy">
                        <span> Order Amount: </span>
                        <div class="form-element c-email-outer">
                        <input
                            type="text" data-name="order amount" data-len="1"
                            id="order-amount" class="form-input"
                            placeholder="Order amount*" v-model="order.orderAmount"
                            @input="handleInput" @blur="validateFields">
                    </div>
                </div>
                </div>
                <div class="order-page-row flex justify-btw align-center">
                    <div class="flex order-page-entry">
                        <span> Deliverd Date: </span> <p> {{ makeDate(order.deliveredOn) }} </p>
                    </div>
                    <div class="flex order-page-entry" v-if="order.fixedBy">
                        <span> Delivered Time: </span> <p> {{ makeTime(order.deliveredOn) }} </p>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="overlay d-none" @click="closeOverlay">
        <div class="modal save-modal d-none" @click="handleModalClick">
            <span @click="closeOverlay"></span>
            <p>Are you sure, Do you want to save the changes?</p>

            <div class="btns-box flex">
                <button class="btn fancy-btn confirm-btn update-order-btn" @click="updateOrder">YES</button>
                <button class="btn fancy-btn cancel-btn" @click="closeOverlay">NO</button>
            </div>
        </div>
        <div class="modal cancel-modal d-none" @click="handleModalClick">
            <span @click="closeOverlay"></span>
            <p>Are you sure, Do you want to cancel the edit?</p>

            <div class="btns-box flex">
                <button class="btn fancy-btn confirm-btn" @click="cancelEdit">YES</button>
                <button class="btn fancy-btn cancel-btn" @click="closeOverlay">NO</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import SideBar from '@/components/admin/SideBar.vue';
import { mapGetters } from 'vuex';
import { Orders } from '@/services/Service';
import $ from 'jquery';
import Datepicker from 'vuejs-datepicker';

const timeStramp = [{time: '9 AM'},{time: '10 AM'},{time: '11 AM'},{time: '12 PM'}];
for(let i = 1; i<9; i++ ) timeStramp.push( { time: i + ' PM' } );

export default {
    data() {
        return {
            order: null,
            timeStramp,
            updatedObj: this.order,
            disabledDates: {
                to: new Date(),
                days: [0]
            }
        }
    },
    components: {
        SideBar,
        Datepicker
    },
    computed: {
        ...mapGetters(['getOrderByID']),
        status() {
            return !this.order.fixed ? "active" : this.order.delivered ? "delivered" : "fixed";
        },
        classObj() {
            return {
                "active": this.status === "active",
                "fixed": this.status === "fixed",
                "delivered": this.status === "delivered"
            }
        },
        makeDate() {
            return date => {
                date = typeof date === "string" ? new Date(date) : date;
                return date.toLocaleDateString('en-IN', { year: 'numeric', month: 'long', day: 'numeric' });
            }
        },
        makeTime() {
            return date => {
                date =  typeof date === "string" ? new Date(date) : date;
                return date.toLocaleString('en-IN', { timeStyle: "short" }).toUpperCase();
            }
        }
    },
    methods: {
        async fetchOrder(id) {
            const resp = await Orders.getByID({ orderID: id });
            this.order = resp.data.result;
        },
        openSaveOverlay() {
            $('.overlay').removeClass('d-none');
            $('.save-modal').removeClass('d-none');
        },
        openCancelOverlay() {
            $('.overlay').removeClass('d-none');
            $('.cancel-modal').removeClass('d-none');
        },
        handleModalClick(e) {
            e.stopPropagation();
        },
        closeOverlay() {
            $('.overlay').addClass('d-none');
            $('.modal').addClass('d-none');
        },
        cancelEdit() {
            const notification = {
                type: 'success',
                message: 'Edit cancelled.'
            }
            this.$store.dispatch('addNotification', notification);
            this.closeOverlay();
            this.$router.push({ name: 'orderPage', params: { id: this.order.orderID } });
        },
        async updateOrder() {
            try {
                $('.update-order-btn').html(`<div class="spinner-border" role="status"></div>`);
                $('.update-order-btn').blur();
                const response = await Orders.edit({ ...this.order });
                if( response.status === 200 )
                {
                    const notification = {
                        type: 'success',
                        message: 'Order updated successfully.'
                    }
                    this.$store.dispatch('addNotification', notification);
                    this.closeOverlay();
                    this.$router.push({ name: 'orderPage', params: { id: this.order.orderID } });
                }
                $('.update-order-btn').html('Yes');
            } catch (error)
            {
                $('.update-order-btn').html('Yes');
                const { message } = error.response.data;
                const notification = { type: 'error', message };
                this.$store.dispatch('addNotification', notification);
            }
        },
        handleInput(e)
        {
            if( e.target.value === '' )
                this.addErrorMessage( e.target.id );
            else
                this.removeErrorMessage( e.target.id );
        },
        validateFields({target: el})
        {
            const { name, len } = el.dataset;
            if(el.value === '')
                this.addErrorMessage( el.id );
            else if( el.value.length < len )
                this.addErrorMessage( el.id, `${name} must have atleast ${len} char.`);
        },
        validateEmail({target: el})
        {
            if(el.value === '')
                return this.addErrorMessage( el.id );

            var atposition = el.value.indexOf("@");
            var dotposition = el.value.lastIndexOf(".");

            if( atposition<1 || dotposition<atposition+2 || dotposition+2>=el.value.length)
                this.addErrorMessage( el.id, `Invalid Email.`);
        },
        addErrorMessage( id, msg )
        {
            $(`.${id}-error-msg`).remove();
            var contents;
            if(msg)
                contents = `<p class="error-msg ${id}-error-msg">${msg}</p>`;
            else
                contents = `<p class="error-msg ${id}-error-msg">${$(`#${id}`).data('name')} can't be empty </p>`;
            $(`#${id}`).parent().addClass('error-parent').append(contents);
        },
        removeErrorMessage(id)
        {
            $(`.${id}-error-msg`).remove();
            $(`#${id}`).parent().removeClass('error-parent');
        }
    },
    created()
    {
        const id = this.$route.params.id;
        this.order = this.getOrderByID( id );
        if( this.order === undefined ) {
            this.fetchOrder(id);
        }
    }
}
</script>

<style scoped>
.order-page
{
    position: absolute;
    top: 0; left: 90px;
    height: 100vh;
    width: calc(100% - 90px);
}

.orders-top-pane
{
    height: 80px;
    border-bottom: 2px solid #f1f1f1;
    padding: 0 25px;
    box-shadow: 2px 2px 4px rgb(0, 0, 0, 0.1);
    background-color: #fff;
}

h2 { font-weight: 800; }

.status
{
    padding: 10px 20px;
    display: inline-block;
    border-radius: 15px;
    color: whitesmoke;
    font-style: normal;
    text-transform: capitalize;
    line-height: 1;
}

.status.active { background-color: #51c2d5; }

.status.fixed { background-color: #00917c; }

.status.delivered { background-color: #ff9a76; }

.order-page-wrapper
{
    margin: 40px 25px;
    height: calc(100vh - 160px);
    border: 2px solid #f1f1f1;
    box-shadow: 2px 2px 4px rgb(0, 0, 0, 0.2);
    background-color: #fff;
    overflow: auto;
    border-right: 0;
    padding-bottom: 25px;
}

.order-page-top-pane
{
    height: 65px;
    padding: 0 25px;
    border-bottom: 2px solid #f1f1f1;
    margin-bottom: 30px;
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    top: 0; left: 0;
}

.save-icon
{
    background-size: 449px 379px;
    width: 41px; height: 41px;
    background-position: -398px -10px;
    cursor: pointer;
    margin-left: auto;
}

.save-icon:hover
{
    background-size: 449px 379px;
    width: 41px; height: 41px;
    background-position: -80px -185px;
}

.cancel-icon
{
    background-size: 368.2px 310.8px;
    width: 41px; height: 41px;
    background-position: -123px -204.2px;
    cursor: pointer;
    margin-left: 12px;
}

.cancel-icon:hover
{
    background-size: 368.2px 310.8px;
    width: 41px; height: 41px;
    background-position: -180.4px -204.2px;
}

.order-page-row
{
    min-height: 50px;
    padding: 0 25px;
    margin: 20px 0;
}

.order-page-entry
{
    width: 50%;
    align-items: center;
}

.order-page-entry>span
{
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-right: 15px;
    align-self: flex-start;
    margin-top: 10px;
    min-width: 225px;
    color: grey;
}

.order-page-entry p
{
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    margin-top: 10px;
}

.order-page-entry p.order-id, .order-page-entry span.order-id
{
    font-family: 'Poppins', sans-serif;
}

.mb-5
{
    margin-bottom: 70px;
    position: relative;
}

.mb-5::after
{
    content: '';
    position: absolute;
    height: 1px; width: 100%;
    bottom: -30px; left: 0;
    border-bottom: 2px solid #f1f1f1;
}

.sub-headings
{
    font-size: 20px;
    margin: 0 25px 24px;
}

.form-element
{
    width: 250px;
    position: relative;
}

.form-input
{
    height: 40px; width: 100%;
    border: 2px solid #000;
    background-color: transparent;
    box-shadow: 3px 3px 4px rgb(255, 255, 255,0.15);
    outline: 0;
    padding-left: 18px;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    border-radius: 3px;
}

.form-input:focus { border-color: #E20613; }

.switch
{
    position: relative;
    display: inline-block;
    width: 50px;
    height: 22px;
    margin-top: 6px;
}

.switch input
{
    opacity: 0;
    width: 0;
    height: 0;
}

.slider
{
    position: absolute;
    cursor: pointer;
    top: 0; left: 0;
    right: 0; bottom: 0;
    background-color: #E20613;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before
{
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider { background-color: #00917c; }

input:focus + .slider { box-shadow: 0 0 1px #00917c; }

input:checked + .slider:before
{
    -webkit-transform: translateX(28px);
    -ms-transform: translateX(28px);
    transform: translateX(28px);
}

.slider.round { border-radius: 34px; }

.slider.round:before { border-radius: 50%; }

.issue-fe { width: 250px; }

.issue { height: 80px; padding: 15px; resize: none; }

.dropdown
{
    background-size: 100.7px 70.3px;
    background-position: -64.7px -2.8px;
	width: 18px; height: 9px;
    position: absolute;
    right: 15px; top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

/* OVERLAY */

.overlay
{
    background-color: rgb(0,0,0,0.5);
    height: 100vh; width: 100vw;
    position: fixed;
    top: 0; left: 0;
    z-index: 10;
}

.modal
{
    position: fixed;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    width: 350px;
    border-radius: 12px;
    padding: 60px 25px 30px;
}

.modal span
{
    position: absolute;
    right: 10px; top: 10px;
    cursor: pointer;
    width: 30px; height: 30px;
}

.modal span::before, .modal span::after
{
    content: '';
    position: absolute;
    width: 3px; height: 30px;
    top: 0; left: 50%;
    background-color: black;
}

.modal span::before { transform: translateX(-50%) rotate(45deg); }

.modal span::after { transform: translateX(-50%) rotate(-45deg); }

.modal span:hover { opacity: 0.5; }

.modal p
{
    font-size: 18px;
    line-height: 1.5;
    font-weight: 500;
    text-align: center;
}

.btns-box { margin-top: 25px; }

.confirm-btn, .cancel-btn
{
    height: 40px; width: 100px;
    border-radius: 3px;
    font-size: 16px;
    padding-top: 3px;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.3);
    color: #fff;
    font-weight: 700;
}

.confirm-btn { background-color: #00917c; margin-left: auto; margin-right: 15px; }

.cancel-btn { background-color: #E20613; margin-right: auto; }

.app-time-label
{
    position: absolute;
    left: 18px;
    top: 15px;
}


</style>


<style>

.exp-date-input-1
{
    height: 45px; width: 250px;
    cursor: context-menu;
    outline: 0;
    background-color: #fff;
    color: black;
    border: 2px solid #000;
    padding-left: 18px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 3px;
}

</style>